<template>
  <div class="bookmark">
    <el-tooltip class="item" effect="dark" :content="value ? 'Unbookmark this freelancer' : 'Bookmark this freelancer'" placement="top">
      <el-button circle @click="toggle">
        <i class="starred" :class="value ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
    },

    methods: {
      toggle () {
        this.$emit('input', ! this.value)
      },
    },
  }
</script>

<style lang="scss">
  .bookmark{
    .starred {
      font-size: 22px;
      color: rgb(247, 186, 42);
      cursor: pointer;
    }
    .el-button {
      -webkit-box-shadow: #e8e8e8 0 1px 4px 0;
      box-shadow: #e8e8e8 0 1px 4px 0;
    }
    .el-button:focus{
      outline: none;
    } 
  }
</style>
<template>
  <upgrade-tooltip action="contact this freelancer">
    <el-button :type="isOnFreePlan ? 'info' : 'warning'" plain @click="sendMessage">Message</el-button>
  </upgrade-tooltip>
</template>

<script>
import isFreelancerButton from '~/mixins/isFreelancerButton'
import Conversation from '~/models/Conversation'

export default {
  mixins: [isFreelancerButton],

  methods: {
    sendMessage () {
      // if on free plan, do nothing
      if (this.isOnFreePlan) {
        return
      }

      // else, create the conversation and redirect to that conversation
      Conversation.createAndRedirect(this.freelancer.data.freelancer_id, 'freelancer')
    },
  },
}
</script>

<style scoped>
.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>

<template>
  <dashboard>
    <el-row id="employer"
            :gutter="15">
      <el-col v-if="freelancer.profile" :xs="24">
        <div v-if="isEmployer" class="back">
          <a href="#" class="mb-3 el-button el-button--info" @click.prevent="$router.go(-1)">
            <i class="mr-2 el-icon-back" />
            Back
          </a>
        </div>
        <div class="jobview">
          <el-card>
            <div class="header">
              <!--MOBILE-->
              <el-row class="visible-xs">
                <bookmark-star v-if="isEmployer" :value="Freelancer.data.bookmark ? true : false" class="bookmark-mobile" @input="bookmarkToggle" />
                <el-col class="text-center">
                  <img :src="profilePhotoUrl(freelancer)" class="mt-2 mb-2" style="height: 120px; width: 120px;" />
                  <h4 class="mb-0" :class="{ blur: freelancer.blurred, 'orange-text': !freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</h4>
                  <h6 v-if="freelancer.profile" class="mb-4">{{ freelancer.profile.title }}</h6>
                  <h6 v-if="freelancer.latest_hire" class="mb-0 bold orange-text">Current employment status</h6>
                  <h6 v-if="freelancer.latest_hire" class="mb-0">Hired on {{ freelancer.latest_hire.created_at | shortFullDate }}.</h6>
                </el-col>
              </el-row>
              <!--DESKTOP-->
              <el-row :gutter="20" type="flex" class="hidden-xs">
                <el-col :xs="24" :sm="4" :md="3" class="">
                  <img :src="profilePhotoUrl(freelancer)" class="w-100" />
                </el-col>
                <el-col :xs="18" :sm="17" :md="19" class="align-self-center hidden-xs">
                  <h4 class="mb-0" :class="{ blur: freelancer.blurred, 'orange-text': !freelancer.blurred }">{{ freelancer.first_name }} {{ freelancer.last_name }}</h4>
                  <h6 v-if="freelancer.profile" class="mb-4">{{ freelancer.profile.title }}</h6>
                  <h6 v-if="freelancer.latest_hire" class="mb-0 bold orange-text">Current employment status</h6>
                  <h6 v-if="freelancer.latest_hire">Hired on {{ freelancer.latest_hire.created_at | shortFullDate }}.</h6>
                </el-col>
                <el-col :xs="6" :sm="3" :md="2" class="text-right align-self-center">
                  <bookmark-star v-if="isEmployer" :value="Freelancer.data.bookmark ? true : false" @input="bookmarkToggle" />
                </el-col>
              </el-row>
            </div>
            <div v-if="isEmployer" class="text-center apply" :class="{ 'cursor-not-allowed-button-wrapper': isOnFreePlan }">
              <upgrade-tooltip action="invite this freelancer to a job">
                <el-button :type="isOnFreePlan ? 'info' : 'warning'" @click="Invite.showDialog()">Invite to Job</el-button>
              </upgrade-tooltip>
              <message-button :freelancer="Freelancer" />
              <mark-as-hired-button :freelancer="Freelancer" />
            </div>
          </el-card>
          <el-dialog v-if="isEmployer" :visible.sync="Invite.showingDialog">
            <span slot="title" class="dialog-title">
              <h4 class="bold orange-text">Invite to Job</h4>
            </span>
            <el-form :model="invite">
              <el-form-item label="Select Job" label-position="top">
                <el-select v-model="invite.job_id" placeholder="Select Job" class="w-100">
                  <el-option v-for="item in jobs" :key="item.job_id" :label="item.title" :value="item.job_id" />
                </el-select>
              </el-form-item>
              <el-form-item label="Message" label-position="top">
                <el-input v-model="invite.message" type="textarea" :rows="2" @input="Invite.clearError('message')" />
              </el-form-item>
              <el-alert
                v-if="Invite.errors.message && Object.keys(Invite.errors.message).length > 0"
                title="Something went wrong. Please check."
                type="error"
                :closable="true"
                effect="dark"
                :show-icon="false">
                <ul class="mb-0">
                  <li v-for="(errorsInField, field) in Invite.errors.message" :key="field">
                    <i class="el-icon-error mr-1" />
                    <span v-text="errorsInField" />
                  </li>
                  <li v-for="(errorsInField, field) in Invite.errors.job_id" :key="field">
                    <i class="el-icon-error mr-1" />
                    <span v-text="errorsInField" />
                  </li>
                </ul>
              </el-alert>
              <el-form-item class="mt-3 text-right">
                <el-button type="info" plain @click="Invite.closeDialog()">Close</el-button>
                <el-button type="warning" :disabled="Invite.saving" :loading="Invite.saving" @click="Invite.save()">Send Invite</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
          <el-card class="mt-3">
            <h5 class="mb-3 orange-text bold">Expected Salary</h5>
            <hr class="mb-3" />
            <p v-if="freelancer.profile && freelancer.profile.full_time_ava" class="mb-0 full">
              <span class="bold">Full-time rate:</span>
              US$ {{ freelancer.profile.full_time_price }}
            </p>
            <p v-if="freelancer.profile && freelancer.profile.part_time_ava" class="mb-0 part">
              <span class="bold">Part-time rate:</span>
              US$ {{ freelancer.profile.part_time_price }}
            </p>
            <p v-if="freelancer.profile && freelancer.profile.hourly_ava" class="mb-0 hourly">
              <span class="bold">Hourly rate:</span>
              US$ {{ freelancer.profile.hourly_price }}
            </p>
          </el-card>
          <el-card class="mt-3">
            <div class="">
              <h5 class="mb-3 orange-text bold">Profile</h5>
              <hr class="mb-3" />
              <div class="intro">
                <p v-if="freelancer.profile">{{ freelancer.profile.intro }}</p>
              </div>
              <div class="description">
                <span v-if="freelancer.profile" v-html="freelancer.profile.description" />
              </div>
            </div>
          </el-card>
          <el-card class="mt-3">
            <div class="">
              <div class="skills">
                <h5 class="mb-3 orange-text bold">Skills</h5>
                <hr class="mb-3" />
                <div v-for="freelancerSkill in freelancer.freelancer_skills" :key="freelancerSkill.skill_id">
                  <div class="skill-tag">
                    <p class="d-inline bold" v-text="freelancerSkill.skill.skill_name" />
                    <el-rate v-if="freelancerSkill.rating" disabled :value="freelancerSkill.rating" class="ml-2 d-inline" />
                    <p v-else class="ml-2 small d-inline">- This skill has not been rated</p>
                  </div>
                </div>
                <div v-if="freelancer.proposed_skills">
                  <div v-for="proposedSkill in freelancer.proposed_skills" :key="proposedSkill.skill_proposal_id">
                    <div class="skill-tag">
                      <p class="d-inline bold" v-text="proposedSkill.skill_name" />
                      <el-rate v-if="proposedSkill.rating" disabled :value="proposedSkill.rating" class="ml-2 d-inline" />
                      <p v-else class="ml-2 small d-inline">- This skill has not been rated</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </dashboard>
</template>

<script>
import moment from 'moment';
import Dashboard from '~/components/Dashboard/Dashboard';
import UpgradeTooltip from '~/components/Employer/UpgradeTooltip';
import BookmarkStar from '~/components/Starred';
import MarkAsHiredButton from '~/components/Freelancer/Buttons/MarkAsHired';
import MessageButton from '~/components/Freelancer/Buttons/Message';
import Freelancer from '~/models/Freelancer';
import JobInvite from '~/models/Jobs/Invite';
import Job from '~/models/Jobs/EmployerJob';
import showsProfilePhoto from '~/mixins/showsProfilePhoto';
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan';
import getUserType from '~/helpers/getUserType';
import Employer from '~/models/Employer';

export default {
  components: {
    Dashboard,
    BookmarkStar,
    UpgradeTooltip,
    MarkAsHiredButton,
    MessageButton
  },

  filters: {
    shortFullDate(value) {
      return moment
        .utc(value)
        .local()
        .format('ll');
    }
  },

  mixins: [showsProfilePhoto, checksIfOnFreePlan],

  props: {
    freelancerId: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      rating: 5,
      Freelancer: new Freelancer(this.freelancerId),
      Invite: getUserType() === 'employer' ? new JobInvite(this.freelancerId) : { data: {} },
      jobs: [],
      bookmark: null
    };
  },

  computed: {
    isEmployer() {
      return getUserType() === 'employer';
    },

    freelancer() {
      return this.Freelancer.data;
    },

    invite() {
      return this.Invite.data;
    },
    isOnFreePlan:'subscription/isOnFreePlan'
  },

  watch: {
    'Invite.showingDialog'(newValue) {
      // if the dialog has been shown and jobs hasn't been fetched yet
      if (this.isEmployer && newValue && this.jobs.length === 0) {
        Job.all(1, 1000, { scope: 'public' }).then(response => {
          this.jobs = response.data.data;
          this.Invite.data.job_id = this.jobs[0].job_id;
        });
      }
    }
  },
  methods: {
    async bookmarkToggle() {
      let bookmark = await Employer.toggleBookmarkedFreelancer(this.Freelancer);
      this.Freelancer.data.bookmark = bookmark;
    }
  }
};
</script>

<style scoped>
#employer .jobview .header {
  padding: 0px 0 20px;
}
#employer .jobview .apply {
  margin: 0;
}
.cursor-not-allowed-button-wrapper button.el-button {
  cursor: not-allowed;
}
.jobview .header {
  padding: 20px 0;
}
#employer .jobview >>> .el-dialog__body {
  padding: 20px;
}

.upgrade:hover {
  color: #e87722;
}
.bookmark-mobile {
  position: absolute;
  right: 0;
}
.visible-xs {
  position: relative;
}
h6 {
  line-height: 1.6;
}
.description {
  font-size: 0.9rem;
  white-space: pre-line;
  margin-bottom: 15px;
}

.description p:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .jobview >>> .el-row--flex {
    /*display: inline-block;*/
  }
  .jobview >>> .el-card__body {
    padding: 15px;
  }
  .jobview .header img {
    display: block;
    margin: 0 auto 20px;
  }
  .apply button {
    margin: 0;
  }
  .apply button:first-child {
    margin-bottom: 10px;
  }
  .apply button:last-child {
    margin-top: 10px;
  }
  .jobview .details .intro p,
  .jobview .details .description p {
    text-align: left;
    line-height: 1.6;
  }
}
</style>
<style lang="scss">
.skill-tag {
  padding: 8px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: inline-block;
  // background: rgba(145, 193, 69, 0.05);
  // border: 1px solid #91c145;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
</style>

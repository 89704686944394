<template>
  <upgrade-tooltip action="mark this freelancer as hired">
    <el-button :class="{ 'cursor-not-allowed': isOnFreePlan }" :type="isOnFreePlan ? 'info' : hired ? 'success' : 'warning'" plain :disabled="freelancer.hiring || hired" :loading="freelancer.hiring" @click="freelancer.hire()">{{ hired ? 'Hired' : 'Mark as Hired' }}</el-button>
  </upgrade-tooltip>
</template>

<script>
import isFreelancerButton from '~/mixins/isFreelancerButton'

export default {
  mixins: [isFreelancerButton],

  computed: {
    hired () {
      const freelancer = this.freelancer.data

      if (! freelancer) {
        return false
      }

      if (! freelancer.hires_by_my_company) {
        return false
      }

      return freelancer.hires_by_my_company.length > 0
    },
  },
}
</script>

<style scoped>
.cursor-not-allowed {
  cursor: not-allowed !important;
}
</style>

import Model from '~/models/Model'
import store from '~/vuex/store'

export default class Invite extends Model {
  constructor (freelancerId) {
    super('', {
      job_id: null,
      freelancer_id: freelancerId,
      message: '',
    })

    this.showingDialog = false

    // check subscription status
    store.dispatch('subscription/checkSubscriptionName')
  }

  static get resource () {
    return 'job_invites'
  }

  showDialog () {
    // if on free plan, disable dialog
    if (store.getters['subscription/isOnFreePlan']) {
      return
    }

    this.showingDialog = true
  }

  closeDialog () {
    this.showingDialog = false
  }

  save () {
    super.save({
      successMessage: 'You have invited the freelancer.',
    }).then(() => this.closeDialog())
  }
}

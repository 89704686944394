import UpgradeTooltip from '~/components/Employer/UpgradeTooltip'
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan'
import Freelancer from '~/models/Freelancer'

export default {
  components: { UpgradeTooltip },

  mixins: [checksIfOnFreePlan],

  props: {
    freelancer: {
      type: Freelancer,
      required: true,
    },
  },
}

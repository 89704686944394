<template>
  <el-tooltip class="item" effect="dark" placement="top" :disabled="! isOnFreePlan">
    <div slot="content" class="text-center">
      Upgrade from the free plan to {{ action }}
      <br>
      <a href="/employer/billing" class="text-underline upgrade"><u>Upgrade Now</u></a>
    </div>
    <slot />
  </el-tooltip>
</template>

<script>
import checksIfOnFreePlan from '~/mixins/checksIfOnFreePlan'

export default {
  mixins: [checksIfOnFreePlan],

  props: {
    action: {
      type: String,
      default: 'do this action',
    },
  },
}
</script>
